import { DateTime, type DateTimeOptions } from 'luxon'

const getTimeZoneByPriority = (timeZone?: string) => {
  if (timeZone) return timeZone

  const userData = JSON.parse(
    localStorage.getItem('userData') as string,
  )

  if (userData?.timeZone) return userData.timeZone

  const systemTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  return systemTimeZone || 'utc'
}

/**
 * A função `useDateUtilsAux` ajusta uma data de acordo com o fuso horário e idioma do usuário.
 *
 * @param {DateTime} date - A data a ser manipulada (instância de Luxon.DateTime).
 * @param {string} [timeZone] - Opcional. Fuso horário para o qual a data será convertida.
 * Se não for fornecido, usa a prioridade definida por `getTimeZoneByPriority`.
 *
 * @returns {DateTime} - A data ajustada com o fuso horário e idioma do usuário.
 */
export const useDateUtilsAux = (date: DateTime, timeZone?: string) => {
  const languageStore = useLanguageStore()

  const options: DateTimeOptions = {
    zone: getTimeZoneByPriority(timeZone),
    locale: languageStore.userLanguage.split('-')[0],
  }

  const dateAux = date.reconfigure(options)

  return dateAux
}
